import React, { ChangeEvent, FunctionComponent, useMemo, useState } from 'react';
import { Permission } from 'auth/getPermissions';
import AuthorizedAction from 'components/common/AuthorizedAction';
import { buildExternalIdentifier, buildScopedToQueryField } from './measurementPointHelpers';
import { generateTimeIntervals, parseURN } from 'util/helper-func';
import { MeasurementPoint, UsageChargeFilter } from 'api/openapi/telemetry';
import { useConnectionAcronymTranslation } from 'hooks';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { isNil } from 'ramda';
import { date, object, string } from 'yup';
import ErrorDisplay, { ErrorModel } from '../../../api/ErrorDisplay';
import { FormRow } from '../../form';
import FluxDatePicker from '../../form/DatePicker';
import FieldWrapper, {
  FieldWidth,
  inputClassNameBuilder,
  selectClassNameBuilder,
} from '../../form/FieldWrapper';
import Alert, { AlertEnum } from '../../layout/Alert';
import { ErrorText } from '../../layout/Errors';
import { isBefore, isDate } from 'date-fns';
import { useListData } from 'list-data/ListDataContext';
import { getMarketFunction } from 'list-data/getMarketFunction';
import { useUsageChargeFilters } from '../../usage-charge-filters/UsageChargeFiltersContext';
import { MarketMap } from '../connectionsApi';
import moment from 'moment';
import 'moment-timezone';
import getConfig from 'config/getConfig';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface MeasurementPointFormProps {
  id: string;
  connectionId: string;
  close: () => void;
  error?: ErrorModel;
  isUpdating: boolean;
  measurementPoint?: MeasurementPoint;
  submitFunction: (measurementPoint: MeasurementPoint) => Promise<any>;
  readOnly: boolean;
}

const displayRegisterField = (currentMarket: string | boolean): boolean => {
  return MarketMap.AU_MARKET === currentMarket;
};

const getParsedIdentifiers = (externalIdentifiers: string | undefined): string[] => {
  if (!externalIdentifiers) return [];
  return parseURN(externalIdentifiers).identifiers;
};
const getTime = (dateToParse: string | undefined, toTime?: boolean, dysn182?: boolean): string => {
  if (!dateToParse) return '';
  const time = moment(dateToParse).tz(getConfig().marketTimezone).format('HH:mm');
  // When a ToTime is at 24:00 midnight it will be formatted to 00:00. We will need to
  // manually map it to 24:00, so it can match the option in the dropdown as ToTime options does not have 00:00.
  if (dysn182 != true && toTime === true && time == '00:00') {
    return '24:00';
  }
  return time;
};

const getFieldDisplay = (parsedURNIdentifiers: string[], indexName: string): string => {
  if (
    parsedURNIdentifiers.includes(indexName) &&
    parsedURNIdentifiers[parsedURNIdentifiers.indexOf(indexName) + 1] !== undefined
  ) {
    return parsedURNIdentifiers[parsedURNIdentifiers.indexOf(indexName) + 1];
  }
  return '';
};

const MeasurementPointForm: FunctionComponent<MeasurementPointFormProps> = ({
  id,
  connectionId,
  close,
  error,
  isUpdating,
  measurementPoint,
  submitFunction,
  readOnly = false,
}) => {
  const cfd490OffMarketMpUi = getConfig().offMarketMeasurementPoints;

  const {
    dysn182UseDefaultTimePickerInMpUi, // LD Client key i dysn-182-use-default-time-picker-in-mp-ui
  } = useFlags();

  const usageChargeFilters = useUsageChargeFilters();
  const [marketFunctionFlags] = useListData(['MARKET_FUNCTION']);
  const currentMarket = getMarketFunction(
    'urn:flux:rating-config:market',
    undefined,
    marketFunctionFlags
  );
  const [isMeter, setIsMeter] = useState(!!measurementPoint?.externalIdentifier);
  const connectionAcronymTranslation = useConnectionAcronymTranslation();
  const isEdit = !isNil(measurementPoint);
  const parsedURNIdentifiers = getParsedIdentifiers(measurementPoint?.externalIdentifier);

  // create time intervals for the from and to time select options
  const intervals = useMemo(() => generateTimeIntervals(30, 'HH:mm', false).concat(['24:00']), []);

  // we need a dynamic yup validation schema as we want to check the start and
  // end dates fall within the current supply period and also the selected plan
  const measurementPointSchema = object().shape({
    measurementPointType: string()
      .label('Measurement Point Type')
      .required('Measurement Point Type is required'),
    usageChargeFilterName: string()
      .label('Usage Charge Filter Name')
      .required('Usage Charge Filter Name is required'),
    startDate: date()
      .typeError('Must be a valid date')
      .label('Starts At')
      .max(new Date('9999-12-31'))
      .required('Start date is required'),
    fromTime: string().label('From time').required(),
    endDate: date()
      .nullable()
      .typeError('Must be a valid date')
      .label('Ends At')
      .max(new Date('9999-12-31'))
      .when('startDate', (startDate: Date, schema: any) => {
        if (isDate(startDate) && isBefore(startDate, new Date('9999-12-31'))) {
          return schema.min(startDate, 'End date must be after start date');
        } else {
          return schema;
        }
      }),
    toTime: string()
      .label('To time')
      .nullable()
      .when('endDate', (value, schema) => {
        if (dysn182UseDefaultTimePickerInMpUi && !value) {
          return schema;
        } else {
          return schema.required();
        }
      }),
    meterSerialNumber: string()
      .nullable()
      .label('Meter Serial Number')
      .when('measurementPointType', (measurementPointType: string, schema: any) => {
        if (measurementPointType === 'Meter') {
          return schema.required('Meter Serial Number is required');
        } else {
          return schema;
        }
      }),
    register: string()
      .nullable()
      .label('Register')
      .when('measurementPointType', (measurementPointType: string, schema: any) => {
        if (displayRegisterField(currentMarket ?? '') && measurementPointType === 'Meter') {
          return schema.required('Register is required');
        } else {
          return schema;
        }
      }),
    flowDirection: string().required('Flow Direction is required').label('Flow Direction'),
    description: string()
      .nullable()
      .label('Description')
      .when('measurementPointType', (measurementPointType: string, schema: any) => {
        if (measurementPointType === 'Calculated') {
          return schema.required('Description is required');
        } else {
          return schema;
        }
      }),
  });

  return (
    <Formik
      initialValues={{
        measurementPointType: isEdit
          ? measurementPoint?.externalIdentifier
            ? 'Meter'
            : 'Calculated'
          : cfd490OffMarketMpUi
          ? 'Calculated'
          : '',
        usageChargeFilterName: isEdit ? `${measurementPoint?.usageChargeFilterName}` : '',
        startDate: isEdit
          ? `${moment(measurementPoint?.startsAt)
              .tz(getConfig().marketTimezone)
              .format('yyyy-MM-DD')}`
          : '',
        endDate: isEdit
          ? measurementPoint?.endsAt
            ? dysn182UseDefaultTimePickerInMpUi
              ? `${moment(measurementPoint?.endsAt)
                  .tz(getConfig().marketTimezone)
                  .format('yyyy-MM-DD')}`
              : `${moment(measurementPoint?.endsAt)
                  .subtract(1, 'second')
                  .tz(getConfig().marketTimezone)
                  .format('yyyy-MM-DD')}`
            : ''
          : '',
        meterSerialNumber: isEdit ? `${getFieldDisplay(parsedURNIdentifiers, 'meter')}` : '',
        register: isEdit ? `${getFieldDisplay(parsedURNIdentifiers, 'register')}` : '',
        description: isEdit ? `${measurementPoint?.description}` : '',
        flowDirection: isEdit
          ? measurementPoint?.isEnergyGoingToConnection === false
            ? 'out'
            : 'in'
          : 'in',
        fromTime: isEdit
          ? `${getTime(measurementPoint.startsAt)}`
          : dysn182UseDefaultTimePickerInMpUi
          ? ''
          : intervals.slice().shift(),
        toTime:
          isEdit && measurementPoint?.endsAt
            ? `${getTime(measurementPoint.endsAt, true, dysn182UseDefaultTimePickerInMpUi)}`
            : dysn182UseDefaultTimePickerInMpUi
            ? ''
            : intervals.slice().pop(),
        isOnMarket: isEdit ? `${measurementPoint?.isOnMarket}` : 'false',
        offMarketConnectionId: isEdit ? `${getFieldDisplay(parsedURNIdentifiers, 'nmi')}` : '',
      }}
      validationSchema={measurementPointSchema}
      onSubmit={({
        usageChargeFilterName,
        startDate,
        endDate,
        fromTime,
        toTime,
        meterSerialNumber,
        register,
        flowDirection,
        description,
      }) => {
        // create start/end date time using market timezone (e.g. Australia/Brisbane), then convert them to UTC (UTC+0).
        const formattedStartDateTime =
          startDate !== ''
            ? moment.tz(`${startDate} ${fromTime}`, getConfig().marketTimezone).toISOString()
            : '';
        // when formatting end date, 24:00 will be formatted to the 00:00 next day.
        const formattedEndDateTime =
          endDate !== ''
            ? moment.tz(`${endDate} ${toTime}`, getConfig().marketTimezone).toISOString()
            : '';

        const externalIdentifier = buildExternalIdentifier(
          connectionAcronymTranslation,
          connectionId,
          meterSerialNumber,
          register
        );

        const measurementPointToSave = {
          ...(isEdit && { id: measurementPoint.id }),
          ...(isEdit && { externalIdentifierKey: measurementPoint.externalIdentifierKey }),
          ...(isMeter && { externalIdentifier: externalIdentifier }),
          scopedTo: buildScopedToQueryField(id),
          description: description,
          usageChargeFilterName: usageChargeFilterName,
          startsAt: formattedStartDateTime,
          endsAt: formattedEndDateTime,
          isEnergyGoingToConnection:
            flowDirection === 'in' ? true : flowDirection === 'out' ? false : null,
          isSystemCreated: false,
        };
        submitFunction(measurementPointToSave);
      }}
    >
      {({ dirty, isValid, errors, touched, handleChange, setFieldValue }) => (
        <Form className="apl-form-layout-v1">
          {error && (
            <Alert type={AlertEnum.DANGER}>
              {isEdit ? 'Could not update measurement point' : 'Could not add measurement point'}
            </Alert>
          )}
          <FormRow>
            <FieldWrapper
              fieldWidth={FieldWidth.HALF}
              htmlFor="measurement-point-type"
              label="Measurement Point Type"
            >
              <Field
                autoComplete="off"
                data-testid="measurement-point-type"
                name="measurementPointType"
                id="measurement-point-type"
                className={selectClassNameBuilder('measurementPointType', errors, touched)}
                as="select"
                disabled={readOnly}
                onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                  handleChange(event);
                  //reset fields
                  setFieldValue('meterSerialNumber', '');
                  setFieldValue('register', '');
                  setFieldValue('description', '');
                  setFieldValue('flowDirection', 'in');
                  //reset flag which fields to show and render this, market in the db is shown as Meter
                  setIsMeter(() => event.target.value === 'Meter');
                }}
              >
                {!cfd490OffMarketMpUi && <option value="" />}
                {(!cfd490OffMarketMpUi || readOnly) && <option value="Meter">Meter</option>}
                <option value="Calculated">Calculated</option>
              </Field>
              <ErrorMessage component={ErrorText} name="measurementPointType" />
            </FieldWrapper>
            <FieldWrapper
              fieldWidth={FieldWidth.HALF}
              htmlFor="usage-charge-filter-name"
              label="Usage Charge Filter Name"
            >
              <Field
                autoComplete="off"
                data-testid="usage-charge-filter-name"
                name="usageChargeFilterName"
                id="usage-charge-filter-name"
                className={selectClassNameBuilder('usageChargeFilter', errors, touched)}
                as="select"
                disabled={readOnly}
              >
                <option value="" />
                {usageChargeFilters.map((usageChargeFilter: UsageChargeFilter) => {
                  return (
                    <option
                      key={usageChargeFilter.id}
                      value={usageChargeFilter.filterName}
                      data-testid={`usage-charge-filter-${usageChargeFilter.filterName}`}
                    >
                      {usageChargeFilter.filterName}
                    </option>
                  );
                })}
              </Field>
              <ErrorMessage component={ErrorText} name="usageChargeFilterName" />
            </FieldWrapper>
          </FormRow>
          {isMeter && cfd490OffMarketMpUi && (
            <FormRow>
              <FieldWrapper
                fieldWidth={FieldWidth.HALF}
                htmlFor="is-on-market"
                label="On Market Indicator"
              >
                <Field
                  autoComplete="off"
                  data-testid="is-on-market"
                  name="isOnMarket"
                  id="is-on-market"
                  className={selectClassNameBuilder('isOnMarket', errors, touched)}
                  as="select"
                  disabled={readOnly}
                >
                  <option value=""></option>
                  <option value="false">Off Market</option>
                  <option value="true">On Market</option>
                </Field>
              </FieldWrapper>
              {measurementPoint?.isOnMarket === false && (
                <FieldWrapper
                  fieldWidth={FieldWidth.HALF}
                  htmlFor="off-market-connection-id"
                  label="Off Market Connection ID"
                >
                  <Field
                    autoComplete="off"
                    data-testid="off-market-connection-id"
                    name="offMarketConnectionId"
                    id="off-market-connection-id"
                    className={inputClassNameBuilder('offMarketConnectionId', errors, touched)}
                    disabled={readOnly}
                  />
                </FieldWrapper>
              )}
            </FormRow>
          )}
          <FormRow>
            <FieldWrapper fieldWidth={FieldWidth.HALF} htmlFor="start-date" label="Starts at">
              <Field
                autoComplete="off"
                data-testid="start-date"
                name="startDate"
                id="start-date"
                component={FluxDatePicker}
                disabled={readOnly}
              />
              <ErrorMessage component={ErrorText} name="startDate" />
            </FieldWrapper>
            <FieldWrapper fieldWidth={FieldWidth.HALF} htmlFor="end-date" label="Ends at">
              <Field
                autoComplete="off"
                data-testid="end-date"
                name="endDate"
                id="end-date"
                component={FluxDatePicker}
                disabled={readOnly}
              />
              <ErrorMessage component={ErrorText} name="endDate" />
            </FieldWrapper>
          </FormRow>
          {dysn182UseDefaultTimePickerInMpUi ? (
            <FormRow>
              <FieldWrapper htmlFor="from-time" label="From time" fieldWidth={FieldWidth.HALF}>
                <Field
                  autoComplete="off"
                  className="apl-text-input-v1"
                  data-testid="from-time-field"
                  name="fromTime"
                  id="from-time"
                  type="time"
                  disabled={readOnly}
                />
                <ErrorMessage component={ErrorText} name="fromTime" />
              </FieldWrapper>
              <FieldWrapper htmlFor="to-time" label="To time" fieldWidth={FieldWidth.HALF}>
                <Field
                  autoComplete="off"
                  className="apl-text-input-v1"
                  data-testid="to-time-field"
                  name="toTime"
                  id="to-time"
                  type="time"
                  disabled={readOnly}
                />
                <ErrorMessage component={ErrorText} name="toTime" />
              </FieldWrapper>
            </FormRow>
          ) : (
            <FormRow>
              <FieldWrapper htmlFor="from-time" label="From time" fieldWidth={FieldWidth.HALF}>
                <Field
                  className="apl-select-v1_0"
                  data-testid="from-time-field"
                  name="fromTime"
                  id="from-time"
                  as="select"
                  disabled={readOnly}
                >
                  {intervals.slice(0, -1).map((value) => (
                    <option key={`from-time-${value}`} value={value}>
                      {value}
                    </option>
                  ))}
                </Field>
                <ErrorMessage component={ErrorText} name="fromTime" />
              </FieldWrapper>
              <FieldWrapper htmlFor="to-time" label="To time" fieldWidth={FieldWidth.HALF}>
                <Field
                  className="apl-select-v1_0"
                  data-testid="to-time-field"
                  name="toTime"
                  id="to-time"
                  as="select"
                  disabled={readOnly}
                >
                  {intervals.slice(1).map((value) => (
                    <option key={`to-time-${value}`} value={value}>
                      {value}
                    </option>
                  ))}
                </Field>
                <ErrorMessage component={ErrorText} name="toTime" />
              </FieldWrapper>
            </FormRow>
          )}
          {isMeter && (
            <FormRow>
              <FieldWrapper
                fieldWidth={FieldWidth.HALF}
                htmlFor="meter-serial-number-field"
                label="Meter Serial Number"
              >
                <Field
                  autoComplete="off"
                  data-testid="meter-serial-number-field"
                  name="meterSerialNumber"
                  id="meter-serial-number-field"
                  className={inputClassNameBuilder('meterSerialNumber', errors, touched)}
                  disabled={readOnly}
                />
                <ErrorMessage component={ErrorText} name="meterSerialNumber" />
              </FieldWrapper>
              {displayRegisterField(currentMarket ?? '') && (
                <FieldWrapper
                  fieldWidth={FieldWidth.HALF}
                  htmlFor="register-field"
                  label="Register"
                >
                  <Field
                    autoComplete="off"
                    data-testid="register-field"
                    name="register"
                    id="register-field"
                    className={inputClassNameBuilder('register', errors, touched)}
                    disabled={readOnly}
                  />
                  <ErrorMessage component={ErrorText} name="register" />
                </FieldWrapper>
              )}
            </FormRow>
          )}
          {
            /*After the feature flag is removed, the Flow direction will be displayed unconditionally (ie please also remove isMeter*/
            (isMeter || cfd490OffMarketMpUi) && (
              <FormRow>
                <FieldWrapper
                  fieldWidth={FieldWidth.HALF}
                  htmlFor="flow-direction-field"
                  label="Flow Direction"
                >
                  <Field
                    autoComplete="off"
                    data-testid="flow-direction-field"
                    name="flowDirection"
                    id="flow-direction-field"
                    as="select"
                    className={selectClassNameBuilder('flowDirection', errors, touched)}
                    disabled={readOnly}
                  >
                    <option value="in">In</option>
                    <option value="out">Out</option>
                  </Field>
                  <ErrorMessage component={ErrorText} name="flowDirection" />
                </FieldWrapper>
              </FormRow>
            )
          }
          {!isMeter && (
            <FormRow>
              <FieldWrapper
                fieldWidth={FieldWidth.FULL}
                htmlFor="description-field"
                label="Description (will be displayed on Customer's invoice)"
              >
                <Field
                  autoComplete="off"
                  data-testid="description-field"
                  name="description"
                  id="description-field"
                  className={inputClassNameBuilder('description', errors, touched)}
                />
                <ErrorMessage component={ErrorText} name="description" />
              </FieldWrapper>
            </FormRow>
          )}
          <div className="apl-display-flex apl-justify-content-end">
            <button
              data-testid="cancel-button"
              className="apl-button-v1"
              onClick={close}
              disabled={isUpdating}
              type="button"
            >
              {readOnly ? 'Close' : 'Cancel'}
            </button>
            {readOnly ? null : (
              <AuthorizedAction
                extraClasses="is-primary"
                isDisabled={isUpdating || !isValid || !dirty}
                permission={isEdit ? Permission.METRIC_EDIT : Permission.METRIC_CREATE}
                testId="save-button"
                title={isUpdating || !isValid || !dirty ? '' : undefined}
                type="submit"
              >
                Save
              </AuthorizedAction>
            )}
          </div>
          <ErrorDisplay error={error} />
        </Form>
      )}
    </Formik>
  );
};

export default MeasurementPointForm;
